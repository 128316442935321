<template>
    <div class="hexiaorecords">
        <!-- 日期筛选 -->
        <div class="date-box">
            <!-- <span>从</span> -->
            <div class="time" @click="showCalendar = true">{{startDate == ''?'开始日期':startDate}}</div>
            <span>-</span>
            <div class="time" @click="showCalendar = true">{{endDate == ''?'结束日期':endDate}}</div>
            <span class="read" @click="dateSearch">搜索</span>
        </div>
        <!-- 核销列表 -->
        <template v-if="isLoad">
            <div :class="['tabbar']" v-if="list.length > 0">
                <van-list v-model="loading" :finished="finished" finished-text="到底啦〜想不到你看到了这里^_^"
                    @load="getCheckByGoods"> 
                    <write-off-statistics-item v-for="(item, index) in list" :key="index" :item="item">
                    </write-off-statistics-item>
                </van-list>
            </div>
            <no-data v-else content="暂无核销记录"></no-data>
        </template>
        <!-- 开始结束日期选择 -->
        <van-calendar v-model="showCalendar" type="range" :min-date="minDate" :max-date="maxDate" confirm-text="完成"
            confirm-disabled-text="请选择结束时间" :default-date="[new Date()]" :allow-same-day="false" color="#E04F29"
            @confirm="onConfirm" />
    </div>
</template>

<script>
    import {
        getCheckByGoods
    } from "@/http/api";
    import WriteOffStatisticsItem from "./WriteOffChildren/WriteOffStatisticsItem";
    export default {
        components: {
            WriteOffStatisticsItem
        },
        data() {
            return {
                startDate: "2020-10-01",
                endDate: new Date(),
                pages: 1,
                finished: true,
                isLoad: false,
                loading: false,
                list: [],
                showCalendar: false,
                date: "",
                minDate: new Date(2020, 0, 1),
                maxDate: new Date(),
                shopUserInfo: {}
            };
        },

        beforeCreate() {
            if (!this.$LStorage.getItem("shopUserInfo")) {
                this.$router.replace({
                    path: "/merchatlogin"
                });
            }
        },

        mounted() {
            this.endDate = this.formatDate(this.endDate);
            this.shopUserInfo = this.$LStorage.getItem("shopUserInfo");
            this.getCheckByGoods();
        },
        methods: {
            //日期格式处理
            formatDate(date) {
                let year = date.getFullYear(),
                    month = ("0" + (date.getMonth() + 1)).slice(-2),
                    day = ("0" + date.getDate()).slice(-2);
                return year + "-" + month + "-" + day;
            },

            // 日期选择完成
            onConfirm(date) {
                const [start, end] = date;
                this.startDate = this.formatDate(start);
                this.endDate = this.formatDate(end);
                this.showCalendar = false;
            },

            //日期筛选
            dateSearch() {
                if (this.startDate == "" || this.endDate == "") {
                    this.$toast("请选择时间");
                    return;
                } else {
                    this.pages = 1;
                    this.finished = true;
                    this.isLoad = false;
                    this.list = [];
                    this.getCheckByGoods();
                }
            },

            //获取核销统计列表
            async getCheckByGoods() {
                const res = await getCheckByGoods({
                    data: {
                        shop_id: this.shopUserInfo.shop_id,
                        pages: this.pages,
                        start_date: this.startDate,
                        end_date: this.endDate
                    }
                });
                console.log(res)
                this.isLoad = true;
                this.loading = false;
                if (res.data.length > 0) {
                    this.pages++;
                    this.list = this.list.concat(res.data);
                    this.finished = true;
                } else {
                    this.finished = false;
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .hexiaorecords {
        width: 100%;
        height: 100vh;
        background: #f6f6f6;
        display: flex;
        flex-direction: column;
        font-size: 0.26rem;

        .date-box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 1rem;
            font-size: 0.28rem;
            color: #333333;
            background-color: #ffffff;

            .time {
                width: 2.35rem;
                height: 0.73rem;
                line-height: 0.73rem;
                background-color: #f5f5f5;
                border-radius: 0.05rem;
                margin: 0 0.1rem;
                color: #2b2b2b;
                font-size: 0.26rem;
                background: #f6f6f6 url("../../../assets/images/riqi.png") no-repeat 0.2rem center;
                background-size: 0.26rem;
                padding-left: 0.56rem;
                box-sizing: border-box;
                position: relative;
        
   }

            .time:after {
                content: "";
                width: 0.17rem;
                height: 0.09rem;
                background: url("../../../assets/images/qiehuan.png") no-repeat center;
                background-size: 100%;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0.2rem;
                margin: auto;
            }

            .read {
                color: #fff;
                width: 1.63rem;
                height: 0.73rem;
                background: #e14f2a;
                border-radius: 0.1rem;
                text-align: center;
                line-height: 0.73rem;
            }
        }

        .tabbar {
            width: 100%;
            height: calc(100vh - 2.79rem);
            overflow-y: auto;
            background: #f6f6f6;
            padding: 0.24rem;
            padding-bottom: 0;
            box-sizing: border-box;
        }

        .tabbar-h-1 {
            height: calc(100vh - 3.79rem);
        }

        .tabbar-h-2 {
            height: calc(100vh - 2.2rem);
        }
    }
</style>
