<template>
    <div class="tabbar-all">
        <div class="tabbar-all-center">
            <img :src="item.cover_imgurl" alt="" />
            <div class="tabbar-all-c-r">
                <p class="goods-name">{{ item.goods_name }}{{ item.goods_name }}</p>
            </div>
        </div>
        <div class="spec-list" v-for="(items, index) in item.specs_lists" :key="index">
            <p class="spec-name">{{ items.specs_item_value }}</p>
            <div class="flex item-box">
                <div class="item">
                    <p class="key">核销数量</p>
                    <p class="number">{{ items.check_total }}</p>
                </div>
                <div class="item">
                    <p class="key">单价</p>
                    <p class="number">￥{{ items.cost_price }}</p>
                </div>
                <div class="item">
                    <p class="key">总计</p>
                    <p class="number">￥{{ (items.check_total * items.cost_price).toFixed(2) }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        components: {},
        props: {
            item: {
                type: Object,
                default () {
                    return {}
                }
            },
        },
        data() {
            return {
                downText: '展开',
                isShow: false
            }
        },
        computed: {
            statusInfo() {
                let status = this.item.cards_status
                switch (status) {
                    case 0:
                        return {
                            text: "已失效", color: "diable"
                        }
                        break;
                    case 1:
                        return {
                            text: "待核销", color: "nouse"
                        }
                        break;
                    case 2:
                        return {
                            text: "已核销", color: "success"
                        }
                        break;
                }
            }
        },
        methods: {
            changeDown() {
                this.isShow = !this.isShow
                if (this.isShow) {
                    this.downText = '折叠'
                } else {
                    this.downText = '展开'
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .tabbar-all {
        background: #fff;
        margin-bottom: 0.3rem;
        border-radius: 0.2rem;
        font-size: 0.26rem;
        box-shadow: 0rem 0.08rem 0.13rem 0rem rgba(0, 0, 0, 0.04);

        .tabbar-all-center {
            width: 100%;
            display: flex;
            padding: 0.2rem;
            box-sizing: border-box;
            border-bottom: 1px solid #f6f6f6;

            img {
                width: 2.46rem;
                height: 1.79rem;
            }

            .tabbar-all-c-r {
                margin-left: 0.2rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .goods-name {
                    width: 100%;
                    font-size: 0.3rem;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    color: #323233;
                    height: 1.79rem;
                }
            }
        }

        .spec-list {
            padding: 0 0.24rem;
            border-bottom: 1px solid #f6f6f6;

            .spec-name {
                font-size: 0.3rem;
                color: #323233;
                line-height: 0.87rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .item-box {
                border-top: 1px solid #f6f6f6;
                height: 1.16rem;
                align-items: center;
                justify-content: space-around;
                text-align: center;

                .item {
                    position: relative;
                    width: 100%;

                    &::after {
                        content: '';
                        width: 1px;
                        height: 0.53rem;
                        background-color: #f6f6f6;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        margin: auto;
                    }

                    &:last-child::after {
                        width: 0;
                    }
                }

                .key {
                    font-size: 0.26rem;
                    color: #7C7C7C;
                    margin-bottom: 0.1rem;
                }

                .number {
                    font-size: 0.3rem;
                    color: #E14F2A;
                }
            }
        }
    }
</style>
